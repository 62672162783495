import { Link, useLocation, useNavigate, useNavigationType } from 'react-router-dom'
import { useTranslation } from 'react-i18n-lite'
import { isEmbedded } from '../../../utils/helpers/iframe'
import { StyledAreaLine, StyledButton } from './NavigationBar.styles'

export default function NavigationBar () {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const navigationType = useNavigationType()
  const { pathname } = useLocation()

  const goBack = () => {
    navigate(-1)
  }

  const isInHome = pathname === '/'
  const hasPreviousPage = navigationType !== 'POP'

  return (
    <>
      {!isEmbedded() && pathname !== '/login' && (
        <StyledAreaLine
          centerAreaElement={
            <>
              <StyledButton
                onClick={goBack}
                disabled={isInHome && !hasPreviousPage}
              >
                {t('navigation.back')}
              </StyledButton>
              <Link to='/'>
                <StyledButton disabled={isInHome}>
                  {t('navigation.home')}
                </StyledButton>
              </Link>
            </>
          }
        />
      )}
    </>
  )
}
